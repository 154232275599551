import React, {Fragment} from 'react';
import HouseInfoXS from './HouseInfoXS';
import HouseInfoLG from './HouseInfoLG';
import AnotherSaleHouseCardSlider from "../../../molecules/AnotherSaleHouseCardSlider";
import SellHome from "../../../molecules/SellHome";
import Container from '@mui/material/Container';
import Head from "next/head";
import {AppState} from "../../../../redux/store";
import {connect} from "react-redux";
import {ObjectBaseInfo} from "../../../../types/object";
import formatAmount from "../../../../utils/formatAmount";
import formatNumber from "../../../../utils/formatNumber";

function getPageTitle(data, houseTypes) {
  const houseType = houseTypes.find(
    (ht) => ht.HouseTypeID === data.HouseTypeId
  ).Type;

  switch (data.TypeID) {
    case 0:
      return `${houseType} med ${data.NumberOfRooms} rok, ${data.Address}, ${data.AreaName}`;
    case 1:
      switch (data.HouseTypeId) {
        case 11:
          return `${houseType} ${formatNumber(data.LotArea)} m², ${data.Address}, ${data.AreaName}`;
        default:
          return `${houseType} med ${data.NumberOfRooms} rum, ${data.Address}, ${data.AreaName}`;
      }
  }
}

function getPageDescription(data: ObjectBaseInfo, houseTypes, municipalities, coaAssociationTypes) {
  const houseType = houseTypes.find(
    (ht) => ht.HouseTypeID === data.HouseTypeId
  ).Type;
  const municipalityName = municipalities.find(m => m.MunicipalityID === data.MunicipalityID).Name
  const coaAssociationTypeName = coaAssociationTypes.find(cat => cat.AssociationTypeID === data.AssociationTypeID).Type

  switch (data.TypeID) {
    case 0:
      return `${coaAssociationTypeName}/${houseType} i ${data.AreaName}, ${municipalityName} till salu. Antal rum ${data.NumberOfRooms} + kök. ${formatAmount(data.Price)} ${data.PriceComment.toLowerCase()}. Boarea ${formatNumber(data.Area)} kvm.`;
    case 1:
      switch (data.HouseTypeId) {
        case 11:
          return `${data.Headline}. Tomt i ${data.AreaName}, ${municipalityName} till salu. ${formatAmount(data.Price)} ${data.PriceComment.toLowerCase()}. Tomtarea ${formatNumber(data.LotArea)} kvm.`;
        default:
          const totalArea = [
            data.BiArea ? `Boarea/biarea ${formatNumber(data.Area)}/${formatNumber(data.BiArea)} kvm` : `Boarea ${formatNumber(data.Area)} kvm`,
            data.LotArea ? `och tomt ${formatNumber(data.LotArea)} kvm` : null
          ]
            .filter(t => !!t)
            .join(" ")
          return `${data.TypeOfConstruction} i ${data.AreaName}, ${municipalityName} till salu. Antal rum ${data.NumberOfRooms} + kök. ${formatAmount(data.Price)} ${data.PriceComment.toLowerCase()}. ${totalArea}.`;
      }
  }
}

const HouseInfo = ({ data, houseTypes, coaAssociationTypes, municipalities }) => {
  const pageTitle = getPageTitle(data, houseTypes)
  const pageDescription = getPageDescription(data, houseTypes, municipalities, coaAssociationTypes)

  return (
    <Fragment>
      <Head>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta
          property="og:image"
          content={`https://static2.privatmaklaren.se/previews/${data.BaseID}.jpg`}
        />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="628" />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
      </Head>
      <div className="desktop">
        <HouseInfoLG data={data} />
      </div>
      <div className="mobile">
        <HouseInfoXS data={data} />
      </div>
      <Container maxWidth="lg">
        <SellHome />
        <AnotherSaleHouseCardSlider />
      </Container>
    </Fragment>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    houseAssociationTypes: state.main.houseAssociationTypes,
    coaAssociationTypes: state.main.coaAssociationTypes,
    municipalities: state.main.municipalities,
    houseTypes: state.main.houseTypes,
    biddingInfo: state.main.biddingInfo,
  };
};

export default connect(mapStateToProps, null)(HouseInfo);
