import {ObjectBaseInfo} from "../../../../types/object";
import {Box, Typography} from "@mui/material";
import React, {Fragment} from "react";

type Husfoto360Props = {
  object: ObjectBaseInfo;
}

const Husfoto360 = (props: Husfoto360Props) => {
  const {object} = props;
  const patterns = [
    /https:\/\/my.matterport.com\/show\/\?m=[A-Za-z0-9-_]{11}/
  ]
  const husfoto360Ids = [];
  object.links.forEach(l => {
    let videoId = null;
    patterns.forEach(p => {
      const match = l.sURL.match(p);
      match && husfoto360Ids.push(match[0])
    })
  })

  if (!husfoto360Ids) {
    return null;
  }

  return (
    <Fragment>
      {husfoto360Ids.length > 0 && (
        <Fragment>
          <div className="husfoto360--title">360</div>
          <Box className="husfoto360">
            {husfoto360Ids.map((id) => (
              <Box
                className="husfoto360"
                sx={{
                  position: 'relative',
                  paddingTop: '56.25%',
                  marginBottom: '1rem',
                }}
              >
                <iframe
                  className="husfoto360--video"
                  src={id}
                  title="3D-länk"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                ></iframe>
              </Box>
            ))}
          </Box>
        </Fragment>
      )}
    </Fragment>
  );
}

export default Husfoto360;
